import React from 'react'
import Helmet from 'react-helmet'

class Imprint extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <html className="is-footer-offset" />
        </Helmet>
      </>
    )
  }
  componentDidMount() {
    setTimeout(() => {
      window.location.href = '/imprint-datenschutz'
    })
  }
}

export default Imprint
